import React, { useEffect, useState } from "react"

const usePageLoading = () => {
  const [pageLoader, setPageLoader] = useState('loading')

  useEffect(() => {
    setPageLoader('loaded')
  }, [setPageLoader])

  return pageLoader
}

export default usePageLoading